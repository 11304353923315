import React, { Component } from 'react';
import Home from './home';

import Layout from '../components/layout';

class App extends Component {
	render() {
		return (
			<Layout>
				<Home />
			</Layout>
		);
	}
}

export default App;
